<template>
  <v-col cols="auto" class="pa-0">
    <v-menu
      v-if="videoLinks.length > 0"
      class="pt-10"
      open-on-hover
      close-delay="120"
      transition="slide-x-transition"
      bottom
      right
      offset-y
      nudge-right="5"
      :close-on-content-click="false">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          color="blue-grey lighten-3"
          v-bind="attrs"
          v-on="on">
          <v-icon>mdi-help-rhombus-outline</v-icon>
        </v-btn>
      </template>
      <v-card class="mx-auto" width="300" tile>
        <v-list dense>
          <v-subheader>Tutorial Videos</v-subheader>
          <v-list-item-group color="primary">
            <v-list-item
              v-for="(link, i) in videoLinks"
              :key="i"
              @click="openVideoPlayer(link.id)">
              {{ link.label }}
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-menu>
    <VideoPlayer
      v-if="showVideoModal"
      v-model="showVideoModal"
      :id="videoId"
      @close="closeVideoPlayer">
    </VideoPlayer>
  </v-col>
</template>
<script lang="js">
const VideoPlayer = () => import('@/components/shared/VideoPlayer.vue')
export default {
  data () {
    return {
      showVideoModal: false,
      videoId: null,
      videoLinks: [
        {
          id: '68667ee9-4aa1-4cc7-bfc5-96e65c98d3b4',
          label: 'Intro to Partner Documents'
        },
        {
          id: '39af1061-d563-42ad-93de-f7c465949de4',
          label: 'Retail Ad Manager'
        }
      ]
    }
  },
  name: 'VideoMenu',
  components: { VideoPlayer },
  methods: {
    openVideoPlayer (id) {
      this.videoId = id
      this.showVideoModal = true
    },
    closeVideoPlayer () {
      this.showVideoModal = false
      this.videoId = null
    }
  }
}
</script>
